import $ from 'jquery';
import 'bootstrap'
import 'owl.carousel';
import 'jquery-validation';


$(document).ready(function () {
    $('#order-form').validate({
        rules: {
            name: {
                required: true
            },
            email: {
                email: true,
                required: true
            },
            tel: {
                required: true
            },
            subject: {
                required: true
            },
            number: {
                required: true
            },

        },


        messages: {
            name: {
                required: "Bölmə doldurulmalıdır"
            },
            email: {
                email: "Etibarlı e-poçta: ex@abc.xyz",
                required: "Bölmə doldurulmalıdır"
            },
            tel: {
                required: "Bölmə doldurulmalıdır"
            },
            subject: {
                required: "Bölmə doldurulmalıdır"
            },
            number: {
                required: "Bölmə doldurulmalıdır"
            },
        }

    });

    $('#contact').validate({
        rules: {
            name: {
                required: true
            },
            email: {
                email: true,
                required: true
            },
            subject: {
                required: true
            },
            message: {
                required: true
            },
        },

        messages: {
            name: {
                required: "Bölmə doldurulmalıdır"
            },
            email: {
                email: "Etibarlı e-poçta: ex@abc.xyz",
                required: "Bölmə doldurulmalıdır"
            },
            subject: {
                required: "Bölmə doldurulmalıdır"
            },
            message: {
                required: "Bölmə doldurulmalıdır"
            },
        }
    });

    $('.block-wrapper_carousel').owlCarousel({
        items: 5,
        loop: false,
        margin: 48,
        nav: true,
        autoWidth: true,
        dots: false,
        navText: [
            '<i class="fa fa-angle-left" aria-hidden="true"></i>',
            '<i class="fa fa-angle-right" aria-hidden="true"></i>'
        ],
    });

    $('.main-slider').owlCarousel({
        items: 1,
        lazyLoad: true,
        loop: true,
        margin: 0,
        dots: false,
        autoplay: true
    });

    const langBar = $('.langbar');
    langBar.on('click', function () {
        $('.langbar-block').slideToggle('fast');
    });

    $(document).mouseup(function (e) {
        if (!langBar.is(e.target) && langBar.has(e.target).length === 0) {
            $(".langbar-block").slideUp();
        }
    });

    // const dropdown = $('._dropdown')
    // const subMenu = $('.sub-menu')
    //
    // dropdown.on('click', function () {
    //     $(this).next(subMenu).slideToggle()
    // });
    //
    // $(document).mouseup(function (e) {
    //     if (!dropdown.is(e.target)
    //         && dropdown.has(e.target).length === 0
    //         && !subMenu.is(e.target)
    //         && subMenu.has(e.target).length === 0) {
    //         $(subMenu).slideUp()
    //     }
    // })

    $('.nav-item').on('click', function () {
        $('.nav-item').removeClass('active');
        $(this).addClass('active');
        $(this).find('.sub-menu-dropdown').slideToggle();
        $(this).siblings('.nav-item').find('.sub-menu-dropdown').slideUp();
    });

    $('.nav-link_dropdown').on('click', function (e) {
        e.preventDefault()
    });

    $(document).mouseup(function (e) {
        if (!$('.nav-item').is(e.target) && $('.nav-item').has(e.target).length === 0) {
            $('.nav-item').find('.sub-menu-dropdown').slideUp();
        }
    });
});